import { Ref } from "vue";

export const readStream = (
  reader: ReadableStreamDefaultReader<string>,
  isSubscribed: Ref<boolean>,
  onData: (data: any) => void
) => {
  reader
    .read()
    .then(({ done, value }) => {
      if (done) {
        console.log("Stream ended");
        isSubscribed.value = false;
        return;
      }

      /* 
      Process SSE lines to remove the "data: " and "event: " prefixes and line breaks.
      These prefixes are added by the backend when emitting messages,
      as specified in the SSE standards:
      https://developer.mozilla.org/en-US/docs/Web/API/Server-sent_events/Using_server-sent_events#event_stream_format 
      */
      const lines = value.split("\n\n");
      for (const chunk of lines) {
        const eventLines = chunk.split("\n");
        let eventType = "";
        let dataStr = "";
        for (const line of eventLines) {
          if (line.startsWith("event: ")) {
            eventType = line.slice(7);
          } else if (line.startsWith("data: ")) {
            dataStr = line.slice(6);
          }
        }
        if (dataStr) {
          try {
            const jsonData = JSON.parse(dataStr);
            onData({ event: eventType, data: jsonData });
          } catch (err) {
            console.error("Error parsing SSE data:", err);
          }
        }
      }

      if (isSubscribed.value) {
        readStream(reader, isSubscribed, onData);
      }
    })
    .catch((error: any) => {
      if (error.name && error.name === "AbortError") {
        console.log("SSE connection aborted");
      } else {
        console.error("Error reading SSE stream:", error);
      }
    });
};
