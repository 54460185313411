<script setup lang="ts">
import { DataSource } from "@/types";
import { onMounted, ref, watch } from "vue";
import { useToolbarStore } from "@/stores/toolbarStore";
import DatasourceDocuments from "./DatasourceDocuments.vue";
import {
  Accordion,
  AccordionItem,
  AccordionTrigger,
  AccordionContent,
} from "@/components/ui/accordion";
import DatasourceAttachedFiles from "./DatasourceAttachedFiles.vue";

const toolbarStore = useToolbarStore();
const datasources = ref<DataSource[]>([]);

onMounted(() => {
  if (toolbarStore.selectedDataSources) {
    datasources.value = toolbarStore.selectedDataSources.filter(
      (ds) => !ds.chat_session_id
    );
    toolbarStore.selectedDataSources.find((ds) => ds.chat_session_id)
      ? (toolbarStore.showAttachedFiles = true)
      : (toolbarStore.showAttachedFiles = false);
  }
});

watch(
  () => toolbarStore.selectedDataSources,
  (selectedDataSources) => {
    if (!selectedDataSources) return;
    datasources.value = selectedDataSources.filter((ds) => !ds.chat_session_id);
    selectedDataSources.find((ds) => ds.chat_session_id)
      ? (toolbarStore.showAttachedFiles = true)
      : (toolbarStore.showAttachedFiles = false);
  }
);
</script>

<template>
  <div class="datasources flex flex-col gap-8">
    <h3
      v-if="!datasources.length && !toolbarStore.showAttachedFiles"
      class="text-sm text-placeholder"
    >
      No datasource selected
    </h3>
    <div
      class="attached_files_list text-sm list-none p-0"
      v-if="toolbarStore.showAttachedFiles"
    >
      <h3 class="font-medium mb-4">Attached files</h3>
      <DatasourceAttachedFiles />
    </div>
    <div
      class="selected_datasources_list text-sm list-none p-0"
      v-if="datasources.length"
    >
      <h3 class="font-medium mb-4">Selected datasources</h3>
      <div class="datasources_accordions flex flex-col gap-4">
        <Accordion type="single" collapsible class="flex flex-col gap-4">
          <AccordionItem
            v-for="datasource in datasources"
            :key="datasource.id"
            :value="datasource.name"
            class="bg-primary10 rounded-md border-b-0 overflow-hidden"
          >
            <AccordionTrigger class="px-2 py-3 bg-primary20 truncate"
              ><span class="font-medium truncate">{{
                datasource.name
              }}</span></AccordionTrigger
            >
            <AccordionContent class="p-2">
              <DatasourceDocuments
                :key="datasource.id"
                :datasource="datasource"
              />
            </AccordionContent>
          </AccordionItem>
        </Accordion>
      </div>
    </div>
  </div>
</template>
