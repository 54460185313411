<script setup lang="ts">
import { FilePlus2, Paperclip, Loader2 } from "lucide-vue-next";
import { onMounted, ref, watch } from "vue";
import { useRoute } from "vue-router";
import { getDataSources } from "@/services/data_source";
import { useTenantStore } from "@/stores/tenant";
import { useQuickUploadDocuments } from "@/composables/useQuickUploadDocuments";
import DataSourceSelector from "@/components/DataSources/DataSourceSelector.vue";
import DataSourceSelectorMenuEntry from "@/components/ChatView/guided-agent-selection/DataSourceSelectorMenuEntry.vue";
import DataSourceCreate from "@/components/DataSources/DataSourceCreate.vue";
import DatasourceIconRounded from "@/components/DataSources/DatasourceIconRounded.vue";
import type { DataSource } from "@/types";
import { useToolbarStore } from "@/stores/toolbarStore";
import { Skills } from "@/types";

const tenantStore = useTenantStore();
const tenantId = ref<string | undefined>(tenantStore.tenantId);
const toolbarStore = useToolbarStore();
const userDataSources = ref<DataSource[]>([]);
const loadingDataSources = ref<boolean>(true);
const route = useRoute();
const sessionId = ref(route.params.session_id as string | undefined);

const reloadDataSources = ref<boolean>(false);

async function loadDataSources() {
  if (!tenantId.value) return;
  try {
    userDataSources.value = await getDataSources(tenantId.value);
  } catch (e) {
    console.error(e);
  } finally {
    loadingDataSources.value = false;
  }
}

onMounted(() => {
  loadDataSources();
});

watch(
  () => tenantStore.tenantId,
  (newTenantId) => {
    if (!newTenantId) return;
    tenantId.value = newTenantId;
    loadDataSources();
  }
);

const { fileInput, onFilesSelected, triggerFileInput, acceptExtensionValue } =
  useQuickUploadDocuments(tenantId, sessionId, (skill) => {
    console.log("Skill selected from DataSourceSelectorMenu:", skill);
  });

const isDataSourceCreateDialogOpen = ref(false);

function onAttachDocumentsClicked() {
  triggerFileInput();
}

async function onFilesSelectedAndReload() {
  await onFilesSelected();
  reloadDataSources.value = true;
}
</script>

<template>
  <Loader2
    v-if="
      (toolbarStore.selectedSkill === Skills.CHAT_WITH_DOCUMENTS ||
        toolbarStore.selectedSkill === Skills.O1_CHAIN) &&
      loadingDataSources
    "
    class="animate-spin"
  />
  <div
    class="data_source_selector_menu w-full"
    v-else-if="
      toolbarStore.selectedSkill === Skills.CHAT_WITH_DOCUMENTS ||
      toolbarStore.selectedSkill === Skills.O1_CHAIN
    "
  >
    <div class="flex flex-col mt-2 ml-0 gap-2 border-y">
      <DataSourceSelector
        v-model="reloadDataSources"
        :icon="DatasourceIconRounded"
        :boxed="false"
      />
    </div>
    <DataSourceSelectorMenuEntry
      :label="'Attach documents to this chat'"
      :hint="'5 documents or less'"
      :icon="Paperclip"
      @click="onAttachDocumentsClicked"
    />

    <DataSourceSelectorMenuEntry
      :label="'Create a data source'"
      :hint="'More than 5 documents'"
      :icon="FilePlus2"
      @click="isDataSourceCreateDialogOpen = true"
    />

    <input
      type="file"
      :accept="acceptExtensionValue"
      ref="fileInput"
      class="hidden"
      multiple
      @change="onFilesSelectedAndReload"
    />

    <DataSourceCreate
      v-model:open="isDataSourceCreateDialogOpen"
      :loadDataSources="loadDataSources"
      :showPlusButton="false"
    />
  </div>
</template>
