<script setup lang="ts">
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";

import { getSkills, setUserSkill } from "@/services/skill";
import { computed, onMounted, ref, watch } from "vue";
import { useTenantStore } from "@/stores/tenant";
import { Loader2, Brain } from "lucide-vue-next";
import { Skill, Skills } from "@/types";
import { useRoute } from "vue-router";
import { useToolbarStore } from "@/stores/toolbarStore";
import { getSelectedDataSources } from "@/services/data_source";

const tenantStore = useTenantStore();
const selectedTenant = ref<string | undefined>(undefined);
const toolbarStore = useToolbarStore();

const shouldLoadDataSources = computed(() => {
  return (
    toolbarStore.selectedSkill === Skills.CHAT_WITH_DOCUMENTS ||
    toolbarStore.selectedSkill === Skills.O1_CHAIN
  );
});

const skills = ref<Skill[]>([]);
const loadingSkills = ref(true);
const selectedSkillName = ref<string | undefined>(Skills.NONE);
const route = useRoute();

onMounted(async () => {
  if (!tenantStore.tenantId) {
    return;
  }
  selectedTenant.value = tenantStore.tenantId;
  await loadSkills();
});

async function loadSkills() {
  if (!tenantStore.tenantId) return;

  loadingSkills.value = true;

  const response = await getSkills(tenantStore.tenantId);
  skills.value = response.skills;

  selectedSkillName.value = toolbarStore.selectedSkill;
  loadingSkills.value = false;
}

watch(selectedSkillName, (newSkillName) => {
  if (newSkillName) {
    const selectedSkill = skills.value.find(
      (skill) => skill.name === newSkillName
    );
    if (!selectedSkill) return;

    if (toolbarStore.selectedSkill !== selectedSkill.name) {
      toolbarStore.setSelectedSkill(selectedSkill.name as Skills);
    }
  }
});

watch(
  () => toolbarStore.selectedSkill,
  async (newSkillName) => {
    if (
      !newSkillName ||
      !tenantStore.tenantId ||
      toolbarStore.selectedSkill === Skills.NONE
    ) {
      toolbarStore.selectedSkill = Skills.NONE;
      return;
    }
    const chat_session_id = route.params.session_id as string;
    loadingSkills.value = true;
    await setUserSkill(
      tenantStore.tenantId as string,
      newSkillName as string,
      chat_session_id
    );

    if (shouldLoadDataSources.value) {
      const userDataSources = await getSelectedDataSources(
        tenantStore.tenantId,
        chat_session_id
      );
      toolbarStore.setSelectedDatasources(userDataSources);
    }

    selectedSkillName.value = newSkillName;
    loadingSkills.value = false;
  }
);

watch(
  () => tenantStore.tenantId,
  async (oldTenantId, newTenantId) => {
    if (oldTenantId === newTenantId) {
      return;
    }

    await loadSkills();
  }
);
</script>

<template>
  <div class="w-fit">
    <Select v-model="selectedSkillName">
      <SelectTrigger
        class="!shadow-md !border-0 truncate w-fit max-w-64 pl-4 pr-3 gap-2"
      >
        <SelectValue class="!flex !items-center justify-between gap-2">
          <Brain class="text-primary shrink-0 w-5 h-5" />
          <p
            class="truncate"
            v-if="!loadingSkills && toolbarStore.selectedSkill !== Skills.NONE"
          >
            {{ selectedSkillName }}
          </p>
          <Loader2
            v-if="loadingSkills || toolbarStore.selectedSkill === Skills.NONE"
            class="animate-spin h-5 w-5"
          />
        </SelectValue>
      </SelectTrigger>
      <SelectContent v-if="!loadingSkills">
        <SelectItem
          v-for="skill in skills"
          :key="skill.name"
          :value="skill.name"
        >
          <p class="flex items-center gap-1">
            {{ skill.name }}
          </p>
          <p class="text-xs text-gray-500">{{ skill.description }}</p>
        </SelectItem>
      </SelectContent>
    </Select>
  </div>
</template>
