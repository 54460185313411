import { useToast } from "@/components/ui/toast/use-toast";
import { getDatasourceByChatSession } from "@/services/data_source";
import { acceptExtensionValue, processFiles } from "@/services/upload_document";
import { useToolbarStore } from "@/stores/toolbarStore";
import { Skills } from "@/types";
import { Ref, ref } from "vue";

export function useQuickUploadDocuments(
  tenantId: Ref<string | undefined>,
  sessionId: Ref<string | undefined>,
  onSelectSkill?: (skill: Skills) => void
) {
  const toast = useToast();
  const toolbarStore = useToolbarStore();

  const fileInput = ref<HTMLInputElement | null>(null);

  const onFilesSelected = async () => {
    if (!sessionId.value || !tenantId.value || !fileInput.value) return;

    const files = fileInput.value.files;
    if (!files) return;

    try {
      await processFiles(
        Array.from(files),
        tenantId.value,
        toast,
        "-1",
        sessionId.value
      );
      const chatSessionDataSource = await getDatasourceByChatSession({
        chatSessionId: sessionId.value,
        tenantId: tenantId.value,
      });

      fileInput.value.value = "";

      if (onSelectSkill) {
        onSelectSkill(Skills.CHAT_WITH_DOCUMENTS);
      }

      const existingSources = toolbarStore.selectedDataSources || [];
      const alreadyIncluded = existingSources.some(
        (ds) => ds.id === chatSessionDataSource.id
      );
      toolbarStore.setSelectedDatasources(
        alreadyIncluded
          ? [chatSessionDataSource]
          : [...existingSources, chatSessionDataSource]
      );

      toolbarStore.reloadDatasourceDocuments = true;
      toolbarStore.selectedMenu = "documents";
    } catch (error) {
      console.error("Error while uploading files:", error);
    }
  };

  const triggerFileInput = () => {
    fileInput.value?.click();
  };

  return {
    fileInput,
    onFilesSelected,
    triggerFileInput,
    acceptExtensionValue,
  };
}
