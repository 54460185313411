<script setup lang="ts">
import { Button } from "@/components/ui/button";
import { Paperclip } from "lucide-vue-next";

import {
  TooltipProvider,
  Tooltip,
  TooltipTrigger,
  TooltipContent,
} from "../ui/tooltip";
import { useQuickUploadDocuments } from "@/composables/useQuickUploadDocuments";
import { computed } from "vue";

const { tenantId, session_id } = defineProps<{
  tenantId: string | undefined;
  session_id: string | undefined;
}>();

const emit = defineEmits(["selectSkill"]);

const tenantIdRef = computed(() => tenantId);
const sessionIdRef = computed(() => session_id);

const { fileInput, onFilesSelected, triggerFileInput, acceptExtensionValue } =
  useQuickUploadDocuments(tenantIdRef, sessionIdRef, (skill) =>
    emit("selectSkill", skill)
  );
</script>

<template>
  <TooltipProvider>
    <div class="upload_button flex justify-center items-center w-8">
      <Tooltip>
        <TooltipTrigger as-child>
          <Button
            variant="ghost"
            class="flex justify-center h-8 w-8 rounded-full p-0"
            @click="triggerFileInput"
          >
            <Paperclip class="h-5 w-5" />
          </Button>
        </TooltipTrigger>
        <TooltipContent side="right">Attach files</TooltipContent>
        <input
          type="file"
          :accept="acceptExtensionValue"
          ref="fileInput"
          class="hidden"
          multiple
          @change="onFilesSelected"
        />
      </Tooltip>
    </div>
  </TooltipProvider>
</template>
