<script setup lang="ts">
import { ref, watch } from "vue";
const props = defineProps<{
  isOn: boolean;
}>();

const currentIndex = ref(0);
let intervalId: number | null = null;
const list_of_words = [
  "Thinking",
  "Retrieving information",
  "Reviewing relevant data",
  "Assessing context",
  "Formulating a response",
  "Refining insights",
  "Integrating findings",
  "Gathering additional details",
  "Correlating patterns",
  "Cross-referencing sources",
  "Synthesizing key points",
  "Distilling essential facts",
  "Evaluating nuances",
  "Optimizing reasoning steps",
  "Verifying assumptions",
  "Ensuring completeness",
  "Double-checking logic",
  "Confirming reliability",
  "Finalizing conclusions",
];

watch(
  () => props.isOn,
  (newVal) => {
    if (newVal) {
      intervalId = setInterval(() => {
        currentIndex.value = (currentIndex.value + 1) % list_of_words.length;
      }, 5000);
    } else {
      clearInterval(intervalId as number);
      intervalId = null;
      currentIndex.value = 0;
    }
  },
  { immediate: true }
);
</script>

<template>
  <div v-if="isOn" class="flex items-center space-x-2">
    <p class="shimmer-text animate-shimmer">
      {{ list_of_words[currentIndex] }}
    </p>
  </div>
</template>
