<script setup lang="ts">
import {
  Collapsible,
  CollapsibleTrigger,
  CollapsibleContent,
} from "@/components/ui/collapsible";
import { Button } from "@/components/ui/button";
import { Separator } from "@/components/ui/separator";
import { X, Check, ChevronsUpDown, Loader2 } from "lucide-vue-next";
import { Item, IndexingStatus } from "@/types";

type IndexingTasksCenterProps = {
  isIndexingTaskCenterOpen: boolean;
  indexingFlows: Item[];
};
const { isIndexingTaskCenterOpen, indexingFlows } =
  defineProps<IndexingTasksCenterProps>();

const emit = defineEmits(["update:isIndexingTaskCenterOpen"]);
</script>
<template>
  <Collapsible
    :open="isIndexingTaskCenterOpen"
    @update:open="emit('update:isIndexingTaskCenterOpen', $event)"
    class="fixed bottom-4 right-4 w-80 bg-background shadow-lg rounded-md overflow-hidden z-20"
  >
    <div class="flex items-center justify-between space-x-4 px-4 py-3">
      <h4>Indexing tasks center</h4>
      <CollapsibleTrigger as-child>
        <Button variant="ghost" size="sm" class="w-9 p-0">
          <ChevronsUpDown class="h-4 w-4" />
          <span class="sr-only">Toggle</span>
        </Button>
      </CollapsibleTrigger>
    </div>

    <CollapsibleContent class="bg-primary10 text-sm">
      <div v-if="!indexingFlows.length" class="py-3 px-4 text-muted-foreground">
        No indexing tasks in progress.
      </div>
      <div v-if="indexingFlows.length" class="py-3 px-4 flex flex-col gap-2">
        <div
          v-for="(flow, index) in indexingFlows"
          :key="flow.id"
          class="space-y-2"
        >
          <div class="flex gap-3 justify-between items-center">
            <p>Indexing flow {{ index + 1 }}</p>
            <div class="flex gap-2 items-center">
              <p>{{ flow.indexing_status?.toLowerCase() }}</p>
              <Loader2
                v-if="
                  flow.indexing_status === IndexingStatus.PENDING ||
                  flow.indexing_status === IndexingStatus.PROCESSING
                "
                class="animate-spin h-4 w-4 text-muted-foreground"
              />
              <div
                v-if="flow.indexing_status === IndexingStatus.INDEXED"
                class="rounded-full bg-primary h-4 w-4 flex items-center justify-center"
              >
                <Check class="h-3 w-3 text-background" />
              </div>
              <div
                v-if="flow.indexing_status === IndexingStatus.ERROR"
                class="rounded-full bg-destructive h-4 w-4 flex items-center justify-center"
              >
                <X class="h-4 w-4 text-background" />
              </div>
            </div>
          </div>
          <Separator
            v-if="index + 1 !== indexingFlows.length"
            class="relative top-0"
          />
        </div>
      </div>
    </CollapsibleContent>
  </Collapsible>
</template>
