import { DataSource, Skills } from "@/types";
import { defineStore } from "pinia";
import { computed, ref, watch } from "vue";

export const useToolbarStore = defineStore("toolbar", () => {
  const selectedMenu = ref<string>("prompts");
  const selectedSkill = ref<Skills>(Skills.NONE);
  const selectedDataSources = ref<DataSource[] | null>(null);
  const showAttachedFiles = ref<boolean>(false);
  const reloadDatasourceDocuments = ref<boolean>(false);
  const hideSelectors = ref<boolean>(false);

  const setSelectedDatasources = (datasources: DataSource[]) => {
    selectedDataSources.value = datasources;
  };

  const setSelectedSkill = (skill: Skills | null) => {
    if (selectedSkill.value === skill) return;
    if (skill === null) {
      selectedSkill.value = Skills.NONE;
    } else {
      selectedSkill.value = skill;
    }
  };

  const hideDocumentButton = computed(() => {
    return (
      selectedSkill.value === Skills.CHAT_WITH_LLM ||
      selectedSkill.value === Skills.CHAT_WITH_WEB ||
      selectedSkill.value === Skills.NONE
    );
  });

  watch(
    () => hideDocumentButton.value,
    (newValue, oldValue) => {
      if (newValue && newValue !== oldValue && newValue === true) {
        selectedMenu.value = "prompts";
      }
    },
    { deep: true }
  );

  return {
    selectedMenu,
    selectedSkill,
    setSelectedSkill,
    selectedDataSources,
    setSelectedDatasources,
    reloadDatasourceDocuments,
    hideDocumentButton,
    showAttachedFiles,
    hideSelectors,
  };
});
