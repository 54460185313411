import { Prompt } from "@/components/ChatView/flow/FlowAccordion.vue";
import apiClient from "@/services/api_client";

export async function getFlowPrompts(tenantId: string, flow_id: string) {
  const url = `/prompts_flow/${flow_id}?tenant_id=${tenantId}`;
  const response = await apiClient.get(url);
  return await response.json();
}

export async function updatePromptAnswer(
  tenantId: string,
  prompt_id: string,
  answer: string
): Promise<Prompt> {
  const url = `/prompt/update?tenant_id=${tenantId}`;
  const body = {
    tenant_id: tenantId,
    id: prompt_id,
    answer: answer,
  };
  const response = await apiClient.patch(url, body);
  return response;
}

export async function updatePromptsFlowAnswer(
  tenantId: string,
  prompts_flow_id: string,
  final_answer: string
): Promise<Prompt> {
  const url = `/prompts_flow/update`;
  const body = {
    tenant_id: tenantId,
    prompts_flow_id: prompts_flow_id,
    final_answer: final_answer,
  };
  const response = await apiClient.patch(url, body);
  return response;
}
