import hljs from "highlight.js";
import "highlight.js/styles/atom-one-dark.css";
import { marked } from "marked";
import validUrl from "valid-url";

function isUrlValid(urlString: string) {
  console.log(validUrl.isUri(urlString));
  return !!validUrl.isUri(urlString);
}

function processMessage(message: string) {
  const linkRegex = /\[([^\]]+)\]\(([^)]+)\)/g;
  return message.replace(linkRegex, (match, p1, p2) => {
    if (isUrlValid(p2)) {
      return match;
    } else {
      return p1;
    }
  });
}

const renderer = new marked.Renderer();

renderer.code = ({ text, lang }) => {
  const { value, language } = hljs.highlightAuto(
    text,
    lang ? [lang] : undefined
  );
  return `<pre><code class="hljs ${language}">${value}</code></pre>`;
};

renderer.link = ({ href, title, text }) => {
  const isValid = href && validUrl.isUri(href);
  const safeHref = isValid ? href : "#";
  const linkTitle = title ? ` title="${title}"` : "";
  return `<a href="${safeHref}"${linkTitle} target="_blank" rel="noopener noreferrer" class="text-blue-600 underline hover:text-primary">${text}</a>`;
};

marked.setOptions({
  renderer,
  gfm: true,
  breaks: true,
  async: false,
});

export function parseMessage(message: string) {
  const processedMessage = processMessage(message);
  const parsed = marked.parse(processedMessage, { async: false });
  return parsed;
}
