import apiClient from "@/services/api_client";
import { DataSource, Item, Role } from "@/types";

type GetDatasourceDocumentsParams = {
  dataSourceIds: number[];
  tenantId: string;
  page: number;
  pageSize: number;
};

type GetChatsessionDocumentsParams = {
  chatSessionId: string;
  tenantId: string;
  page: number;
  pageSize: number;
};

type GetDatasourceByChatSession = {
  chatSessionId: string;
  tenantId: string;
};

export type GetDatasourcesReponse = {
  items: Item[];
  count: number;
};
export async function getDataSources(tenantId: string): Promise<DataSource[]> {
  const url = "/datasources?tenant_id=" + tenantId;
  const response = await apiClient.get(url);
  return await response.json();
}

export async function getDatasourceByChatSession({
  chatSessionId,
  tenantId,
}: GetDatasourceByChatSession) {
  const url: string = `/datasource/${chatSessionId}?tenant_id=${tenantId}`;

  const response = await apiClient.get(url);
  return await response.json();
}

export async function getSelectedDataSources(
  tenantId: string,
  chatSessionId: string
): Promise<DataSource[]> {
  const url = `/datasources/multi-selected?tenant_id=${tenantId}&chat_session_id=${chatSessionId}`;
  const response = await apiClient.get(url);
  return await response.json();
}

export async function selectDataSources(
  chatSessionId: string,
  dataSources: DataSource[],
  selectDataSourceNames: string[],
  tenantId: string
) {
  const selectedDataSourceIds = dataSources
    .filter((dataSource) => selectDataSourceNames.includes(dataSource.name))
    .map((dataSource) => dataSource.id);

  const url = "/datasources/multi-selected";
  await apiClient.post(url, {
    data_source_ids: selectedDataSourceIds,
    tenant_id: tenantId,
    chat_session_id: chatSessionId,
  });
}

export async function reIndexDatasourceErrorFiles(
  dataSourceId: number,
  tenantId: string
) {
  const url = "/retry_index";
  const body = {
    data_source_id: dataSourceId,
    tenant_id: tenantId,
  };
  await apiClient.post(url, body);
}

export async function createDataSource(
  name: string,
  tenantId: string,
  visibility: string,
  description?: string
) {
  const url = "/datasources";
  return await apiClient.post(url, {
    name,
    tenant_id: tenantId,
    visibility: visibility,
    description: description,
  });
}

export async function updateDataSource(
  dataSourceName: string,
  dataSourceId: number,
  tenantId: string,
  visibility?: string,
  description?: string
) {
  const url = "/datasources/update";

  await apiClient.post(url, {
    name: dataSourceName,
    data_source_id: dataSourceId,
    tenant_id: tenantId,
    visibility: visibility,
    description: description,
  });
}

export async function deleteDataSource(
  dataSourceName: string,
  dataSourceId: number,
  tenantId: string
) {
  const url = "/datasources";
  await apiClient.delete(url, {
    name: dataSourceName,
    data_source_id: dataSourceId,
    tenant_id: tenantId,
  });
}

export async function getDataSourceById(
  dataSourceId: string,
  tenantId: string
): Promise<DataSource> {
  const url = `/datasources/${dataSourceId}?tenant_id=${tenantId}`;
  const response = await apiClient.get(url);
  return await response.json();
}

export async function getDatasourceDocuments({
  dataSourceIds,
  tenantId,
  page,
  pageSize,
}: GetDatasourceDocumentsParams): Promise<GetDatasourcesReponse> {
  const params = new URLSearchParams();
  params.append("tenant_id", tenantId);
  params.append("page", page.toString());
  params.append("size", pageSize.toString());

  dataSourceIds.forEach((id) =>
    params.append("data_source_ids", id.toString())
  );

  const url = `/datasources/get_documents?${params.toString()}`;

  const response = await apiClient.get(url);
  return await response.json();
}

export async function getChatSessionDocuments({
  chatSessionId,
  tenantId,
  page,
  pageSize,
}: GetChatsessionDocumentsParams) {
  const url: string = `/chat_session/${chatSessionId}/get_documents?tenant_id=${tenantId}&page=${page}&size=${pageSize}`;

  const response = await apiClient.get(url);
  return await response.json();
}

export async function getUserDataSourceRole(
  dataSourceId: string,
  tenantId: string
) {
  const url = `/datasources/${dataSourceId}/role?tenant_id=${tenantId}`;
  const response = await apiClient.get(url);
  const role = await response.json();

  if (!isValidRole(role)) {
    throw new Error("Invalid role");
  }

  return role;
}

function isValidRole(role: string): role is Role {
  return Object.values(Role).includes(role as Role);
}
