<script setup lang="ts">
import { ref, watch } from "vue";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Loader } from "lucide-vue-next";

import { useAuthStore } from "@/stores/auth";
import { useUserStore } from "@/stores/user";
import { useConfigCatStore } from "@/stores/configCat";
import { useTenantStore } from "@/stores/tenant";
import { reIndexDatasourceErrorFiles } from "@/services/data_source";
import Input from "@/components/ui/input/Input.vue";

const auth = useAuthStore();
const user = useUserStore();
const tenantStore = useTenantStore();
const configCatStore = useConfigCatStore();

const tenantId = ref<string>(tenantStore.tenantId || "");
const dataSourceId = ref<number>();

const handleRetryIndexing = async () => {
  if (tenantId.value && dataSourceId.value) {
    const dataSourceIdNumber = Number(dataSourceId.value);
    if (!isNaN(dataSourceIdNumber)) {
      try {
        reIndexDatasourceErrorFiles(dataSourceIdNumber, tenantId.value);
        console.log(dataSourceIdNumber);
      } catch (e) {
        console.log(e);
      } finally {
        dataSourceId.value = undefined;
      }
    } else {
      console.error("dataSourceId is not a valid number");
    }
  }
};

watch(
  () => tenantStore.tenantId,
  async (oldTenantId, newTenantId) => {
    if (!newTenantId || oldTenantId === newTenantId) {
      return;
    }
    tenantId.value = newTenantId;
  }
);
</script>

<template>
  <Card v-if="auth.isLoading">
    <CardHeader>
      <Loader class="animate-spin h-5 w-5 mt-4" />
    </CardHeader>
  </Card>
  <Card v-else class="h-screen">
    <CardHeader>
      <CardTitle> Welcome, {{ user.name }} </CardTitle>
    </CardHeader>
    <CardContent class="flex flex-col gap-8">
      Email: {{ user.email }}
      <div class="flex flex-col gap-4" v-if="configCatStore.retryIndexing">
        <h3 class="text-lg">
          Retry indexing for files with
          <span class="font-medium">Error Status:</span>
        </h3>
        <div class="flex gap-3 items-center max-w-64">
          <Input
            placeholder="Data source id"
            v-model="dataSourceId"
            class="placeholder:text-placeholder"
          />
          <Button class="shrink-0" size="sm" @click="handleRetryIndexing">
            Retry indexing
          </Button>
        </div>
      </div>
    </CardContent>
  </Card>
</template>
