<script setup lang="ts">
import { computed, onMounted, ref, watch, Component } from "vue";
import {
  getDataSources,
  getSelectedDataSources,
  selectDataSources,
  getDatasourceByChatSession,
} from "@/services/data_source";
import { useTenantStore } from "@/stores/tenant";
import { DataSource } from "@/types";
import { useToolbarStore } from "@/stores/toolbarStore";
import { Button } from "@/components/ui/button";
import DataSourceCreate from "@/components/DataSources/DataSourceCreate.vue";
import { useRoute } from "vue-router";
import DataSourceMultiSelect from "@/components/DataSources/DataSourceMultiSelect.vue";
import { FilePlus2 } from "lucide-vue-next";
import { Skills } from "@/types";
type Props = {
  icon?: Component;
  boxed?: boolean;
};

const reloadDataSources = defineModel();

const props = withDefaults(defineProps<Props>(), {
  icon: undefined,
  boxed: true,
});

const tenantStore = useTenantStore();
const toolbarStore = useToolbarStore();
const selectedTenant = ref<string | undefined>("");

const route = useRoute();
const chatSessionId = ref<string | undefined>(
  route.params.session_id as string
);

const dataSources = ref<DataSource[]>([]);
const loadingDataSources = ref(true);
const selectedDataSources = ref<string[]>([]);
const menuOpen = ref(false);

const noDataSource = computed(() => dataSources.value.length === 0);

const displayedSelectedDataSources = computed(() => {
  if (selectedDataSources.value.length === 0) {
    return "Select a data source";
  }
  if (selectedDataSources.value.length === 1) {
    return selectedDataSources.value[0] !== chatSessionId.value
      ? truncateString(selectedDataSources.value[0], 20)
      : "Attached files";
  }

  return `${
    selectedDataSources.value[0] !== chatSessionId.value
      ? truncateString(selectedDataSources.value[0], 15)
      : "Attached files"
  } +${selectedDataSources.value.length - 1}`;
});

function truncateString(str: string, maxLength: number): string {
  return str.length > maxLength ? `${str.slice(0, maxLength)}...` : str;
}

async function loadDataSources() {
  if (!selectedTenant.value || !chatSessionId.value) {
    return;
  }

  loadingDataSources.value = true;

  try {
    const [attachedFilesDatasource, datasources, userDataSources] =
      await Promise.all([
        getDatasourceByChatSession({
          chatSessionId: chatSessionId.value as string,
          tenantId: selectedTenant.value,
        }),
        getDataSources(selectedTenant.value),
        getSelectedDataSources(selectedTenant.value, chatSessionId.value),
      ]);

    dataSources.value = attachedFilesDatasource
      ? [attachedFilesDatasource, ...datasources]
      : [...datasources];

    const attachedFilesdatasourceSelected = userDataSources.find(
      (datasource) => datasource.chat_session_id
    );

    if (
      attachedFilesdatasourceSelected &&
      attachedFilesdatasourceSelected.id !== attachedFilesDatasource.id
    ) {
      const index = userDataSources.indexOf(attachedFilesdatasourceSelected);
      userDataSources.splice(index, 1, attachedFilesDatasource);
      await selectDataSources(
        chatSessionId.value,
        dataSources.value,
        userDataSources.map((dataSource) => dataSource.name),
        selectedTenant.value
      );
    }

    toolbarStore.setSelectedDatasources(userDataSources);
    selectedDataSources.value = userDataSources.map(
      (dataSource) => dataSource.name
    );
  } catch (error) {
    console.error("Failed to load data sources:", error);
  } finally {
    loadingDataSources.value = false;
  }
}

onMounted(async () => {
  selectedTenant.value = tenantStore.tenantId;
  chatSessionId.value = route.params.session_id as string;
  await loadDataSources();
});

watch(
  () => tenantStore.tenantId,
  async (oldTenantId, newTenantId) => {
    if (oldTenantId === newTenantId) {
      return;
    }
    selectedTenant.value = tenantStore.tenantId;
    await loadDataSources();
  }
);

const showDataSourceSelector = computed(() => {
  return (
    (toolbarStore.selectedSkill === Skills.CHAT_WITH_DOCUMENTS ||
      toolbarStore.selectedSkill === Skills.O1_CHAIN) &&
    !toolbarStore.hideSelectors
  );
});

watch(
  () => chatSessionId.value,
  async () => {
    await loadDataSources();
  }
);

watch(
  () => route.params.session_id,
  async (newSessionId) => {
    chatSessionId.value = newSessionId as string;
  }
);

watch(
  () => toolbarStore.selectedDataSources,
  async (selection) => {
    if (selection) {
      selectedDataSources.value = selection.map(
        (dataSource) => dataSource.name
      );
    }
  }
);

watch(menuOpen, async (newVal, oldVal) => {
  if (!tenantStore.tenantId || !chatSessionId.value) {
    return;
  }
  if (oldVal && !newVal) {
    const selectedSources = dataSources.value.filter((dataSource) =>
      selectedDataSources.value.includes(dataSource.name)
    );

    if (selectedSources) {
      toolbarStore.setSelectedDatasources(selectedSources);
    }

    await selectDataSources(
      chatSessionId.value,
      dataSources.value,
      selectedDataSources.value,
      tenantStore.tenantId
    );
  }
});
watch(
  () => reloadDataSources.value,
  async (newVal) => {
    if (newVal === true) {
      await loadDataSources();
      reloadDataSources.value = false;
    }
  }
);

const isDataSourceCreateDialogOpen = ref(false);
</script>

<template>
  <div class="w-full bg-background" :class="boxed ? 'rounded-md' : ''">
    <Button
      v-if="showDataSourceSelector && noDataSource && !loadingDataSources"
      @click="isDataSourceCreateDialogOpen = true"
      class="!shadow-md !border-0"
    >
      <div class="flex items-center gap-2">
        <FilePlus2 class="text-white shrink-0 w-5 h-5" />
        <p class="truncate font-normal">Create a data source</p>
      </div>
    </Button>
    <DataSourceMultiSelect
      v-model:menuOpen="menuOpen"
      v-model:selectedDataSources="selectedDataSources"
      :dataSources="dataSources"
      :displayedSelectedDataSources="displayedSelectedDataSources"
      :loadingDataSources="loadingDataSources"
      :icon="props.icon"
      :boxed="props.boxed"
    />

    <DataSourceCreate
      v-model:open="isDataSourceCreateDialogOpen"
      :loadDataSources="loadDataSources"
      :showPlusButton="false"
    />
  </div>
</template>
