<script setup lang="ts">
import { ref, Ref, inject, computed, watch, onMounted, nextTick } from "vue";
import apiClient from "@/services/api_client";
import { File, FolderClosed, Loader, X, Eye, EyeOff } from "lucide-vue-next";
import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogTrigger,
  AlertDialogAction,
} from "@/components/ui/alert-dialog";
import { useTenantStore } from "@/stores/tenant";
import {
  Pagination,
  PaginationPrev,
  PaginationNext,
} from "@/components/ui/pagination";
import { Checkbox } from "@/components/ui/checkbox";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  TableCaption,
} from "@/components/ui/table";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
  CardFooter,
} from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Item, Folder, DataSource } from "@/types";
import { useToast } from "@/components/ui/toast/use-toast";
import { useAuthStore } from "@/stores/auth";
import { Dialog, DialogContent, DialogTrigger } from "./ui/dialog";
import SharepointSearchBar from "@/components/SharepointSearchBar.vue";

const dataSource = inject<Ref<DataSource>>("dataSource");
const { unfoldTasksCenterVisibility } = defineProps<{
  unfoldTasksCenterVisibility: (value: boolean) => void;
}>();

// Reactive State
const folder = ref<Folder>({
  items: [],
});
const loading = ref(true);
const urlHistory = ref<string[]>([]);
const pathHistory = ref<Item[]>([
  { name: "root", id: "root", item_type: "folder" },
]);
const selectedOptions = ref<{ [key: string]: boolean }>({});
const selectedAll = ref<boolean>(false);
const next_page_url = ref<string>("");
const newSiteUrl = ref<string>("");
const newSiteId = ref<string>("");
const isSitesReadAll = ref<boolean>(true);

// Initialize the tenant store
const tenantStore = useTenantStore();
const selectedTenant = ref<string>(tenantStore.tenantId || "");
const userToken = ref<string | undefined>("");
const areSharepointCredentialsSet = ref<boolean>(false);
const azureTenantId = ref<string>("");
const microsoftAppId = ref<string>("");
const microsoftAppPassword = ref<string>("");
const showPassword = ref<boolean>(false);
const areInvalidCredentials = ref<boolean>(false);
const toast = useToast();
const isConnectButtonDisabled = computed(() => {
  return (
    !azureTenantId.value || !microsoftAppId.value || !microsoftAppPassword.value
  );
});
const authStore = useAuthStore();

async function handleCreateSharePointCredentials() {
  if (isConnectButtonDisabled.value) {
    return;
  }
  const url = "/sharepoint/credentials";
  const body = {
    tenant_id: selectedTenant.value,
    azure_tenant_id: azureTenantId.value,
    microsoft_app_id: microsoftAppId.value,
    microsoft_app_password: microsoftAppPassword.value,
  };
  const response = await apiClient.post(url, body);
  if (response?.error) {
    areSharepointCredentialsSet.value = false;
    areInvalidCredentials.value = true;
    azureTenantId.value = "";
    microsoftAppId.value = "";
    microsoftAppPassword.value = "";
    console.error(response.error);
    return;
  }
  areSharepointCredentialsSet.value = true;
  areInvalidCredentials.value = false;
}

async function getFolderItems(
  newFolder: boolean = false,
  sendNextUrl: boolean = false,
  searchQuery?: string
) {
  if (!selectedTenant.value) return;

  if (isSitesReadAll.value && !userToken.value) return;

  selectedOptions.value = {};
  loading.value = true;

  if (newFolder) {
    urlHistory.value = [];
  }
  const url = "/sharepoint/items";
  const body = {
    site_id: folder.value.site_id,
    drive_id: folder.value.drive_id,
    drive_item_id: folder.value.drive_item_id,
    next_page_url: sendNextUrl ? next_page_url.value : "",
    search_query: searchQuery,
    tenant_id: selectedTenant.value,
    access_token: userToken.value,
    from_teams: authStore.isInsideTeams,
  };
  try {
    const response = await apiClient.post(url, body);
    folder.value.items = response.items;
    next_page_url.value = response.next_page_url;
  } catch (error) {
    console.error(error);
  }
  loading.value = false;
}

// Pagination functions
async function nextPageItems() {
  urlHistory.value.push(next_page_url.value);
  await getFolderItems(false, true);
}

async function previousPageItems() {
  urlHistory.value.pop();
  next_page_url.value = urlHistory.value[urlHistory.value.length - 1];
  if (!next_page_url.value) {
    next_page_url.value = "";
  }
  await getFolderItems(false, true);
}

async function openFolder(item: Item, isSearch: boolean = false) {
  if (item.item_type === "file") {
    return;
  } else {
    if (isSearch) {
      pathHistory.value = [{ name: "root", id: "root", item_type: "folder" }];
    }
    pathHistory.value.push(item);
    folder.value = {
      site_id: item.site_id,
      drive_id: item.drive_id,
      drive_item_id: item.drive_item_id,
      items: [],
    };
    await getFolderItems(true);
  }
}

async function goBack(folder_id: string) {
  const index = pathHistory.value.findIndex((path) => path.id === folder_id);
  pathHistory.value = pathHistory.value.slice(0, index + 1);
  folder.value = {
    ...pathHistory.value[index],
    items: [],
  };
  await getFolderItems(true);
}

// Selection functions
function handleChange(checked: boolean, id: string) {
  if (!checked && selectedAll.value) {
    selectedAll.value = false;
  }
  selectedOptions.value[id] = checked;
}

function selectAllChange() {
  selectedAll.value = !selectedAll.value;
  folder.value.items.forEach((item) => {
    selectedOptions.value[item.id] = selectedAll.value;
  });
}

function isButtonDisabled() {
  const selectedItems = Object.keys(selectedOptions.value).filter(
    (key) => selectedOptions.value[key]
  );
  return selectedItems.length === 0;
}

// Index selected items
async function indexSelectedItems() {
  const selectedItems = Object.keys(selectedOptions.value).filter(
    (key) => selectedOptions.value[key]
  );
  const items: Item[] = [];

  folder.value.items.forEach((item) => {
    if (selectedItems.includes(item.id)) {
      items.push(item);
    }
  });

  selectedOptions.value = {};
  loading.value = true;
  urlHistory.value = [];
  const url = "/sharepoint/index";
  const body = {
    data_source_id: dataSource?.value.id.toString(),
    items: items,
    tenant_id: selectedTenant.value,
  };
  try {
    await apiClient.post(url, body);
    toast.toast({
      title: "Indexing in progress",
      description: "File indexing started, it may take a few minutes",
      duration: 3000,
    });
  } catch (error) {
    toast.toast({
      title: "Uh oh! Something went wrong",
      description: "There was a problem with file indexing",
      variant: "destructive",
      duration: 3000,
    });
    console.error("Error indexing files", error);
  } finally {
    unfoldTasksCenterVisibility(true);
  }
  loading.value = false;
}

async function getSharePointCredentialsStatus() {
  if (!selectedTenant.value) return; // Check if selectedTenant is available

  const credentials = await apiClient.getSharePointCredentials(
    selectedTenant.value
  );
  areSharepointCredentialsSet.value = Boolean(credentials);
}

async function getConnectionType() {
  if (!selectedTenant.value) return; // Check if selectedTenant is available

  isSitesReadAll.value = await apiClient.isSiteReadAll(selectedTenant.value);
}

async function addSiteUrl() {
  const url = "/sharepoint/add_site_url";
  const body = {
    site_url: newSiteUrl.value,
    site_id: newSiteId.value,
    tenant_id: selectedTenant.value,
  };
  loading.value = true;
  await apiClient.post(url, body);
  newSiteUrl.value = "";
  newSiteId.value = "";
  await getFolderItems();
}

function getSiteIdUrl() {
  return newSiteUrl.value + "/_api/site/id";
}

onMounted(async () => {
  getSharePointCredentialsStatus();
  await nextTick();
  await getConnectionType();
  await getFolderItems();
  if (tenantStore.tenantId) {
    await authStore.updateCredentials(tenantStore.tenantId);
    userToken.value = await authStore.aquireToken();
  }
});

watch(
  () => areSharepointCredentialsSet.value,
  async (value) => {
    if (value) {
      getConnectionType();
    }
  }
);

watch([() => userToken.value, tenantStore.tenantId], async () => {
  await getFolderItems();
});
</script>

<template>
  <Dialog v-if="areSharepointCredentialsSet">
    <DialogTrigger as-child>
      <Button variant="outline" class="flex justify-center h-8 m-2">
        <img
          src="@/assets/sharepoint-icon.png"
          alt="SharePoint"
          class="w-4 h-4 mr-2"
        />
        Upload from SharePoint
      </Button>
    </DialogTrigger>
    <DialogContent class="max-h-full !max-w-full w-[80vw] h-[80vh] !p-0">
      <Card class="flex flex-col h-full border-0 !shadow-none overflow-hidden">
        <CardHeader class="flex flex-col gap-4">
          <div class="flex items-center justify-between w-full">
            <CardTitle class="flex-auto">Sharepoint documents</CardTitle>
          </div>
          <SharepointSearchBar
            v-if="isSitesReadAll"
            :token="userToken"
            :openFolder="openFolder"
          />
          <CardDescription
            >Add your sharepoint document to Playground index</CardDescription
          >
          <div class="flex items-center justify-between">
            <Breadcrumb class="mt-2">
              <BreadcrumbList>
                <BreadcrumbItem
                  v-for="(path, index) in pathHistory"
                  :key="index"
                >
                  <BreadcrumbLink
                    class="hover:cursor-pointer text-blue-700"
                    @click="goBack(path.id)"
                    >{{ path.name }}</BreadcrumbLink
                  >
                  <BreadcrumbSeparator
                    v-if="index !== pathHistory.length - 1"
                    class="list-none"
                  />
                </BreadcrumbItem>
              </BreadcrumbList>
            </Breadcrumb>
            <Pagination class="flex align-baseline">
              <PaginationPrev
                v-if="urlHistory.length > 0"
                @click="previousPageItems"
                class="w-6 h-6"
              />
              <CardDescription
                v-if="next_page_url || urlHistory.length"
                class="flex items-center mr-3 ml-3"
                >Page {{ urlHistory.length + 1 }}</CardDescription
              >
              <PaginationNext
                v-if="next_page_url"
                @click="nextPageItems"
                :disabled="!next_page_url"
                class="w-6 h-6"
              />
            </Pagination>
          </div>
        </CardHeader>
        <CardContent class="h-full overflow-y-scroll">
          <div class="flex flex-col overflow-y-auto grow">
            <Table aria-describedby="Sharepoint list" class="min-h-32">
              <TableCaption v-if="!loading"
                >List of the sharepoint documents</TableCaption
              >
              <TableHeader>
                <TableRow>
                  <TableHead class="w-1/4 text-left">Name</TableHead>
                  <TableHead
                    class="flex items-center justify-end space-x-2 text-center"
                  >
                    <span>Select | all</span>
                    <Checkbox
                      :checked="selectedAll"
                      @update:checked="selectAllChange"
                    />
                  </TableHead>
                  <TableHead class="text-center">
                    <Button
                      :disabled="isButtonDisabled()"
                      @click="indexSelectedItems"
                      class="h-8"
                      >Index</Button
                    >
                  </TableHead>
                </TableRow>
              </TableHeader>
              <Loader v-if="loading" class="animate-spin h-5 w-5 mt-5" />
              <TableBody v-else>
                <TableRow v-for="item in folder.items" :key="item.id">
                  <TableCell
                    :class="[
                      item.item_type === 'folder' ? 'hover:cursor-pointer' : '',
                    ]"
                    class="font-medium flex items-center gap-4"
                    @click="openFolder(item)"
                  >
                    <span v-if="item.item_type == 'folder'">
                      <FolderClosed
                        class="h-4 w-4"
                        stroke-width="3"
                        color="#0344C3"
                      />
                    </span>
                    <span v-else>
                      <File class="h-4 w-4" />
                    </span>
                    {{ item.name }}
                  </TableCell>
                  <TableCell class="text-right">
                    <Checkbox
                      :checked="selectedOptions[item.id]"
                      @update:checked="
                        (newChecked) => handleChange(newChecked, item.id)
                      "
                    />
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>
        </CardContent>
        <CardFooter
          class="flex flex-col grow ml-6 mt-10 mb-1"
          v-if="!isSitesReadAll"
        >
          <div class="flex-grow"></div>

          <div class="flex w-full">
            <div class="flex grow w-max">
              <Input
                v-model="newSiteUrl"
                class="grow"
                placeholder="Enter new Sharepoint Site url"
              />
              <Input
                v-model="newSiteId"
                class="ml-2 grow"
                placeholder="Site Id"
              />
              <Button class="ml-2" @click="addSiteUrl">Add</Button>
              <a
                v-if="newSiteUrl"
                :href="getSiteIdUrl()"
                target="_blank"
                class="text-xs text-gray-500 ml-2"
                >* Help for site id</a
              >
            </div>
          </div>
        </CardFooter>
      </Card>
    </DialogContent>
  </Dialog>
  <AlertDialog v-else>
    <AlertDialogTrigger as-child>
      <div class="flex flex-col">
        <Button variant="outline" class="flex justify-center h-8 m-2">
          <img
            src="@/assets/sharepoint-icon.png"
            alt="SharePoint"
            class="w-4 h-4 mr-2"
          />
          Connect to SharePoint
        </Button>
        <p v-if="areInvalidCredentials" class="pl-2 text-red-500 text-sm">
          Invalid Credentials
        </p>
      </div>
    </AlertDialogTrigger>
    <AlertDialogContent>
      <AlertDialogHeader>
        <div class="flex items-center justify-between">
          <CardTitle>Connect to SharePoint</CardTitle>
          <AlertDialogCancel as-child>
            <Button variant="outline" class="rounded-full w-9 h-9 p-2">
              <X class="h-5 w-5" />
            </Button>
          </AlertDialogCancel>
        </div>
      </AlertDialogHeader>
      <AlertDialogDescription>
        <form @submit.prevent="handleCreateSharePointCredentials">
          <div class="mb-4">
            <label
              for="azureTenantId"
              class="block text-sm font-medium text-gray-700"
              >Azure Tenant ID</label
            >
            <input
              type="text"
              id="azureTenantId"
              v-model="azureTenantId"
              class="mt-1 p-2 border border-gray-300 rounded-md w-full"
              required
              autocomplete="off"
            />
          </div>
          <div class="mb-4">
            <label
              for="microsoftAppId"
              class="block text-sm font-medium text-gray-700"
              >Microsoft App ID</label
            >
            <input
              type="text"
              id="microsoftAppId"
              v-model="microsoftAppId"
              class="mt-1 p-2 border border-gray-300 rounded-md w-full"
              required
              autocomplete="off"
            />
          </div>
          <div class="mb-4">
            <label
              for="microsoftAppPassword"
              class="block text-sm font-medium text-gray-700"
              >Microsoft App Password</label
            >
            <div class="relative flex">
              <input
                :type="showPassword ? 'text' : 'password'"
                id="microsoftAppPassword"
                v-model="microsoftAppPassword"
                class="mt-1 p-2 border border-gray-300 rounded-md w-full"
                required
                autocomplete="off"
              />
              <button
                type="button"
                @click="showPassword = !showPassword"
                class="ml-4"
              >
                <Eye v-if="!showPassword" class="h-5 w-5" />
                <EyeOff v-else class="h-5 w-5" />
              </button>
            </div>
          </div>
          <AlertDialogFooter>
            <AlertDialogAction type="submit" :disabled="isConnectButtonDisabled"
              >Connect</AlertDialogAction
            >
          </AlertDialogFooter>
        </form>
      </AlertDialogDescription>
    </AlertDialogContent>
  </AlertDialog>
</template>
