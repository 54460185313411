<script setup lang="ts">
import {
  ref,
  onMounted,
  onBeforeUnmount,
  nextTick,
  provide,
  watch,
  computed,
} from "vue";
import { SendHorizontal, Loader2, Square } from "lucide-vue-next";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import { Textarea } from "@/components/ui/textarea";
import MessageDisplay from "@/components/MessageDisplay.vue";
import apiClient from "@/services/api_client";
import {
  TooltipProvider,
  Tooltip,
  TooltipTrigger,
  TooltipContent,
} from "@/components/ui/tooltip";
import {
  connectWebSocket,
  sendMessage,
  isProcessingMessage,
  messages,
  websocket,
  disconnectWebSocket,
  sendStopSignal,
  isAbleToStop,
} from "@/services/websocket";
import { Message, Skills, SavedPromptsList } from "@/types";
import ChatToolbar from "@/components/ChatToolbar.vue";
import { useTenantStore } from "@/stores/tenant";
import { useConfigCatStore } from "@/stores/configCat";
import TourChat from "@/components/TourChat.vue";
import { fetchOnboardingTourStatus } from "@/services/onboarding_tour";
import { useAuthStore } from "@/stores/auth";
import { getSavedPrompts } from "@/services/saved_prompts";
import { useRoute, useRouter } from "vue-router";
import { useChatSessionsStore } from "@/stores/chatSessions";
import { useSavedPromptStore } from "@/stores/savedPrompts";
import DropZone from "@/components/DropZone.vue";
import { processFiles } from "@/services/upload_document";
import { useToast } from "@/components/ui/toast";
import { getUserSkill } from "@/services/skill";
import QuickUploadButton from "@/components/ChatView/QuickUploadButton.vue";
import { useToolbarStore } from "@/stores/toolbarStore";
import { getDatasourceByChatSession } from "@/services/data_source";
import GuidedAgentSelector from "@/components/ChatView/guided-agent-selection/GuidedAgentSelector.vue";

const message = ref("");
const fetchedMessages = ref<Message[]>([]);
const isLoadingMessages = ref(true);
const isLoadingMoreMessages = ref(false);
const textareaId = "message";
const pressEnterToSend = ref(true);
const startIndex = ref(0);
const numMessagesToFetch = 10;
const totalMessages = ref(0);
const savedPrompts = ref<SavedPromptsList[]>([]);
const tenantStore = useTenantStore();
const configCatStore = useConfigCatStore();
const tourComponentsLoaded = ref<boolean>(false);
const readyForTour = ref<boolean>(false);
const authStore = useAuthStore();
const route = useRoute();
const router = useRouter();
const session_id = ref(route.params.session_id as string | undefined);
const chatSessionsStore = useChatSessionsStore();
const toolbarStore = useToolbarStore();
const savedPromptStore = useSavedPromptStore();
const toast = useToast();

const userScrolled = ref<boolean>(false);
const SCROLL_THRESHOLD = 10; // px from bottom to consider "scrolled away"

const lastBotMessageIndex = computed(() => {
  for (let i = messages.value.length - 1; i >= 0; i--) {
    if (messages.value[i].type === "bot") {
      return i;
    }
  }
  return -1;
});

const disableTextArea = computed(() => {
  return (
    toolbarStore.selectedSkill === Skills.NONE ||
    (toolbarStore.selectedSkill === Skills.CHAT_WITH_DOCUMENTS &&
      (toolbarStore.selectedDataSources === null ||
        toolbarStore.selectedDataSources.length === 0))
  );
});

// ------ FONCTIONS------ //
const adjustTextareaHeight = () => {
  const textarea = document.getElementById(textareaId) as HTMLTextAreaElement;
  if (textarea) {
    textarea.style.height = "auto";
    if (message.value !== "") {
      textarea.style.height = `${Math.min(textarea.scrollHeight, 260)}px`;
    }
  }
};

const setLoadingState = (incrementIndex: boolean, state: boolean) => {
  if (incrementIndex) {
    isLoadingMoreMessages.value = state;
  } else {
    isLoadingMessages.value = state;
  }
};

const fetchMessages = async (incrementIndex: boolean = false) => {
  try {
    setLoadingState(incrementIndex, true);
    await fetchMessagesFromApi(incrementIndex);
  } catch (error) {
    console.error("Error fetching messages:", error);
  } finally {
    setLoadingState(incrementIndex, false);
    await nextTick();
    scrollToBottom();
  }
};

const fetchMessagesFromApi = async (incrementIndex: boolean) => {
  if (
    (incrementIndex && fetchedMessages.value.length >= totalMessages.value) ||
    !session_id.value
  ) {
    return;
  }

  await apiClient
    .get(
      `/messages?num_messages=${numMessagesToFetch}&start_index=${startIndex.value}&session_id=${session_id.value}`
    )
    .then(async (res) => {
      const data = await res.json();
      const newMessages: Message[] = data.messages.map((msg: Message) => ({
        ...msg,
        isLoading: false,
      }));
      totalMessages.value = data.total_messages;
      await updateMessages(newMessages, incrementIndex);
    })
    .catch((err) => console.error("Error fetching messages:", err.statusText));
};

const updateMessages = async (
  newMessages: Message[],
  incrementIndex: boolean
) => {
  if (incrementIndex) {
    await addMoreMessages(newMessages);
    startIndex.value += newMessages.length;
  } else {
    fetchedMessages.value = newMessages;
    messages.value = newMessages;
    startIndex.value = newMessages.length;
    await nextTick();
    scrollToBottom();
  }
};

const addMoreMessages = async (newMessages: Message[]) => {
  const container = document.getElementById("message-container");
  if (container) {
    const previousScrollHeight = container.scrollHeight;
    const previousScrollTop = container.scrollTop;
    fetchedMessages.value = [...newMessages, ...fetchedMessages.value];
    messages.value = [...newMessages, ...messages.value];
    await nextTick();
    container.scrollTop =
      container.scrollHeight - previousScrollHeight + previousScrollTop;
  }
};

const getPrompts = async () => {
  const tenantId = tenantStore.tenantId;
  if (!tenantId) {
    return;
  }
  const response = await getSavedPrompts(tenantId);
  savedPrompts.value = response;
};

const insertPrompt = async (content: string) => {
  message.value = content;
  await nextTick();
  adjustTextareaHeight();
};

async function setupChat() {
  setupEventListeners();
  await nextTick();
  await fetchMessages();
  if (tenantStore.tenantId) {
    const skill = await getUserSkill(
      tenantStore.tenantId as string,
      session_id.value as string
    );
    if (skill) {
      toolbarStore.setSelectedSkill(skill);
    }
    await authStore.updateCredentials(tenantStore.tenantId);
  }

  await getPrompts();
  connectWebSocket("chat");
  adjustTextareaHeight();
  scrollToBottom();
}

const redirectToLastSession = async (tenantId: string) => {
  await chatSessionsStore.fetchChatSessions(tenantId);
  const lastSessionId = chatSessionsStore.chatSessions[0].id;
  router.replace({ name: "Chat", params: { session_id: lastSessionId } });
};

async function updateChatSessionName(message: string, session_id: string) {
  const name = message.split(" ").slice(0, 10).join(" ");
  await chatSessionsStore.updateChatSession(session_id, name);
}

async function initializeSession() {
  if (session_id.value) {
    return true;
  }

  if (tenantStore.tenantId) {
    await redirectToLastSession(tenantStore.tenantId);
    return false;
  }

  const unwatchTenantId = watch(
    () => tenantStore.tenantId,
    async (newTenantId) => {
      if (newTenantId) {
        await redirectToLastSession(newTenantId);
        unwatchTenantId();
      }
    }
  );

  return false;
}

const handleInput = (event: Event) => {
  savedPromptStore.insertPrompt("");
  message.value = (event.target as HTMLTextAreaElement).value;
  adjustTextareaHeight();
};

const handleSubmit = async (event: Event) => {
  event.preventDefault();
  if (message.value.trim() !== "" && !isProcessingMessage.value) {
    const token = await authStore.aquireToken();
    const tenantId = tenantStore.tenantId;
    if (!tenantId) {
      return;
    }
    if (totalMessages.value === 0) {
      const chatSession = chatSessionsStore.chatSessions.find(
        (chatSession) => chatSession.id === session_id.value
      );
      // set name equal to the 10 first words of the first chat message, if not renamed yet
      if (chatSession && chatSession.name === "New Chat") {
        await updateChatSessionName(message.value, session_id.value as string);
      }
    }
    const datasourceIds = toolbarStore.selectedDataSources
      ? toolbarStore.selectedDataSources.map((dataSource) => dataSource.id)
      : [];
    const skill = toolbarStore.selectedSkill;
    if (!session_id.value || datasourceIds === undefined) {
      return;
    }
    sendMessage(
      message.value,
      tenantId,
      session_id.value,
      skill,
      datasourceIds,
      token,
      authStore.isInsideTeams
    );
    resetMessageField();
    adjustTextareaHeight();
    await nextTick();
    scrollToBottom(true);

    chatSessionsStore.moveChatSessionToTop(session_id.value as string);
  }
};

const handleStop = () => {
  isAbleToStop.value = false;
  sendStopSignal();
};

const handleRetry = async (index: number) => {
  let message = undefined;
  const skill = toolbarStore.selectedSkill;
  for (let i = index - 1; i >= 0; i--) {
    if (messages.value[i].type === "user") {
      message = messages.value[i].content;
      break;
    }
  }
  if (!message || !skill) {
    return;
  }
  await handleMessageAction(message, skill);
};

const handleCreateFlow = async (index: number) => {
  const message_to_develop = messages.value[index].content;
  const instruction = "Create a flow from this message";
  const message = `${instruction}\n ${message_to_develop}`;
  const skill = Skills.CREATE_FLOW;
  console.log("Developing message at index", index);
  await handleMessageAction(message, skill);
};

const handleMessageAction = async (message: string, skill: string) => {
  const token = await authStore.aquireToken();
  const tenantId = tenantStore.tenantId;
  if (!tenantId) {
    return;
  }
  const datasourceIds = toolbarStore.selectedDataSources
    ? toolbarStore.selectedDataSources.map((dataSource) => dataSource.id)
    : [];
  if (!session_id.value || datasourceIds === undefined) {
    return;
  }
  sendMessage(
    message,
    tenantId,
    session_id.value,
    skill,
    datasourceIds,
    token,
    authStore.isInsideTeams
  );
  resetMessageField();
  adjustTextareaHeight();
  await nextTick();
  scrollToBottom(true);
  chatSessionsStore.moveChatSessionToTop(session_id.value as string);
};

const handleKeydown = (event: KeyboardEvent) => {
  if (pressEnterToSend.value && event.key === "Enter" && !event.shiftKey) {
    event.preventDefault();
    handleSubmit(event);
  }
};

const resetMessageField = () => {
  message.value = "";
  savedPromptStore.insertPrompt("");
};

const handleFilesDropped = async (files: FileList) => {
  if (!session_id.value || !tenantStore.tenantId) return;

  try {
    await processFiles(
      Array.from(files),
      tenantStore.tenantId,
      toast,
      "-1",
      session_id.value
    );

    const chatSessionDataSource = await getDatasourceByChatSession({
      chatSessionId: session_id.value as string,
      tenantId: tenantStore.tenantId,
    });
    if (toolbarStore.selectedSkill !== Skills.O1_CHAIN) {
      toolbarStore.setSelectedSkill(Skills.CHAT_WITH_DOCUMENTS);
    }
    toolbarStore.setSelectedDatasources(
      toolbarStore.selectedDataSources &&
        !toolbarStore.selectedDataSources.find(
          (dataSource) => dataSource.id === chatSessionDataSource.id
        )
        ? [...toolbarStore.selectedDataSources, chatSessionDataSource]
        : [chatSessionDataSource]
    );
    toolbarStore.reloadDatasourceDocuments = true;
    toolbarStore.selectedMenu = "documents";
  } catch (error) {
    console.error("Error processing files:", error);
  }
};

const isNearBottom = (container: HTMLElement): boolean => {
  const threshold = SCROLL_THRESHOLD;
  const bottomPosition =
    container.scrollHeight - container.scrollTop - container.clientHeight;
  return bottomPosition < threshold;
};

const scrollToBottom = (forced: boolean = false) => {
  const container = document.getElementById("message-container");
  if (!container) return;

  if (!userScrolled.value || isNearBottom(container) || forced) {
    const targetScroll = container.scrollHeight;
    container.scrollTop = targetScroll;
  }
};

const handleManualScroll = async () => {
  const container = document.getElementById("message-container");
  if (!container) return;

  if (container.scrollTop === 0) {
    if (
      fetchedMessages.value.length >= numMessagesToFetch &&
      fetchedMessages.value.length < totalMessages.value
    ) {
      await fetchMessages(true);
    }
  }

  const wasNearBottom = isNearBottom(container);
  if (userScrolled.value !== !wasNearBottom) {
    userScrolled.value = !wasNearBottom;
  }
};

const handleAutoScroll = () => {
  const container = document.getElementById("message-container");
  if (!container) return;

  if (isNearBottom(container)) {
    userScrolled.value = false;
  }
};

// ------ EVENT LISTENERS ------ //
const setupEventListeners = () => {
  const textarea = document.getElementById(textareaId) as HTMLTextAreaElement;
  if (textarea) {
    textarea.addEventListener("keydown", handleKeydown);
  }

  const container = document.getElementById("message-container");
  if (container) {
    container.addEventListener("scroll", handleManualScroll);
    container.addEventListener("scroll", handleAutoScroll);
  }
};

const removeEventListeners = () => {
  const container = document.getElementById("message-container");
  if (container) {
    container.removeEventListener("scroll", handleManualScroll);
    container.removeEventListener("scroll", handleAutoScroll);
  }
};

// ------ LIFE CYCLE ------ //
onMounted(async () => {
  const shouldProceed = await initializeSession();
  if (!shouldProceed) return;
  savedPromptStore.insertPrompt("");
  await setupChat();
  tourComponentsLoaded.value = true;
});

onBeforeUnmount(() => {
  if (websocket.value) {
    disconnectWebSocket(1000);
  }
  removeEventListeners();
});

// ------ WATCHERS ------ //
watch(
  [() => tourComponentsLoaded.value, () => tenantStore.tenantId],
  async ([tourComponentsLoadedValue, tenantId]) => {
    if (tenantId) {
      const onboardingStatus = await fetchOnboardingTourStatus(
        "chat",
        tenantId
      );
      readyForTour.value = tourComponentsLoadedValue && onboardingStatus;
    } else {
      readyForTour.value = false;
    }
  }
);

watch(
  messages,
  () => {
    toolbarStore.hideSelectors = messages.value.length === 0;
    if (userScrolled.value) return;
    scrollToBottom();
  },
  { deep: true }
);

watch(
  () => savedPromptStore.selectedPromptContent,
  async (newSavedPrompts, oldSavedPrompts) => {
    if (!newSavedPrompts || newSavedPrompts === oldSavedPrompts) return;
    insertPrompt(newSavedPrompts);
  }
);

watch(
  () => route.params.session_id,
  async (newSessionId, oldSessionId) => {
    if (newSessionId !== oldSessionId) {
      session_id.value = newSessionId as string | undefined;
      startIndex.value = 0;
      fetchedMessages.value = [];
      totalMessages.value = 0;
      const shouldProceed = await initializeSession();
      if (!shouldProceed) return;
      await setupChat();
    }
  }
);

watch(
  () => tenantStore.tenantId,
  async (newTenantId, oldTenantId) => {
    if (!session_id.value || !newTenantId || oldTenantId === newTenantId) {
      return;
    }

    await redirectToLastSession(newTenantId);
  }
);

provide("startIndex", startIndex);
provide("fetchedMessages", fetchedMessages);
provide("messages", messages);
provide("totalMessages", totalMessages);
</script>

<template>
  <TourChat v-if="readyForTour && configCatStore.onboarding" />
  <div class="w-full relative flex h-screen flex-1">
    <ChatToolbar />
    <!-- Drop Zone Overlay -->
    <DropZone @files-dropped="handleFilesDropped">
      <!-- DISABLE WHEN NO MESSAGE -->
      <div
        id="message-container"
        class="h-full w-full max-w-[860px] m-auto overflow-y-auto hide-scrollbar px-8 relative pb-36 pt-16 flex flex-col"
        :class="messages.length === 0 ? 'justify-center' : 'justify-start'"
        ref="dropZoneRef"
      >
        <div class="flex flex-col">
          <div v-if="isLoadingMessages" class="flex flex-col gap-4">
            <Skeleton class="w-3/4 h-32 self-end bg-primary20" />
            <Skeleton class="w-3/4 h-8 self-start bg-grey10" />
            <Skeleton class="w-3/4 h-8 self-start bg-grey10" />
            <Skeleton class="w-1/2 h-8 self-start bg-grey10" />
            <Skeleton class="w-2/3 h-24 self-end bg-primary20" />
            <Skeleton class="w-3/5 h-8 self-start bg-grey10" />
            <Skeleton class="w-1/3 h-8 self-start bg-grey10" />
          </div>
          <div v-else class="flex flex-col">
            <GuidedAgentSelector v-if="messages.length === 0" />
            <div v-if="isLoadingMoreMessages" class="flex justify-center my-2">
              <Loader2 class="animate-spin w-6 h-6 text-gray-500" />
            </div>
            <div
              v-for="(msg, index) in messages"
              :key="index"
              :class="
                msg.type === 'bot' || msg.type === 'error'
                  ? 'self-start'
                  : 'self-end items-end'
              "
              class="flex flex-col max-w-2xl w-full"
            >
              <MessageDisplay
                :message="msg.content"
                :type="msg.type"
                :isLoading="msg.isLoading"
                :sources="msg.sources"
                :flowId="msg.flowId"
                :tenantId="tenantStore.tenantId"
                :index="index"
                :displayLastBotButtons="
                  !(isProcessingMessage && index === lastBotMessageIndex)
                "
                @retry="handleRetry"
                @createFlow="handleCreateFlow"
              />
            </div>
          </div>
        </div>
      </div>
      <div
        class="search-container flex flex-col justify-center gap-1 absolute bottom-4 w-full px-4"
      >
        <div
          class="search-form w-full max-w-[860px] m-auto flex flex-col gap-2 bg-background rounded-lg px-5 pt-2 pb-3 shadow-md"
          id="search_bar"
        >
          <form
            @submit="handleSubmit"
            class="w-full relative flex gap-4 items-end"
          >
            <div class="search-input flex flex-1">
              <Label for="message" class="sr-only"> Message </Label>
              <Textarea
                id="message"
                v-model="message"
                placeholder="Type your message here..."
                class="h-auto min-h-7 max-h-48 resize-none pt-4 pb-0 px-0 focus:ring-0 border-0 text-sm leading-5"
                spellcheck="false"
                :disabled="disableTextArea"
                @input="handleInput"
              />
            </div>
            <TooltipProvider>
              <Tooltip v-if="isAbleToStop">
                <TooltipTrigger as-child>
                  <Button
                    @click="handleStop"
                    type="submit"
                    size="sm"
                    class="flex items-center rounded-full h-10 w-10 shrink-0"
                  >
                    <Square class="w-5 h-5" />
                  </Button>
                </TooltipTrigger>
                <TooltipContent>Stop</TooltipContent>
              </Tooltip>
              <Tooltip v-else>
                <TooltipTrigger as-child>
                  <Button
                    :disabled="isProcessingMessage || disableTextArea"
                    type="submit"
                    size="sm"
                    class="flex items-center rounded-full h-10 w-10 shrink-0"
                  >
                    <SendHorizontal class="w-5 h-5" />
                  </Button>
                </TooltipTrigger>
                <TooltipContent>Send</TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </form>
          <div class="search-options flex justify-between items-center">
            <QuickUploadButton
              :tenantId="tenantStore.tenantId"
              :session_id="session_id"
              @selectSkill="toolbarStore.setSelectedSkill"
            />
            <p class="text-xs text-muted-foreground">
              Use <kbd class="bg-primary30">shift + Enter</kbd> for new line
            </p>
          </div>
        </div>
      </div>
    </DropZone>
  </div>
</template>

<style>
.hide-scrollbar {
  scrollbar-width: none !important; /* Firefox */
  -ms-overflow-style: none !important; /* Internet Explorer 10+ */
}

.hide-scrollbar::-webkit-scrollbar {
  display: none !important; /* Chrome, Safari, Opera */
}
</style>
